

export enum ClientState {
  Active = 1,
}

export class ClientData {
  organizationName: string;
  locationName: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  locationEmail: string;
  locationNotes: string;
  deliveryNotes: string;

  paymentNotes: string;
  printNotes: string;

  commission: string;
  shipping: string;

  brewers: any[];
  grinders_brew: any[];
  filters_brew: any[];

  espresso: any[];
  grinders_esp: any[];
  filters_esp: any[];

  water_tower: any[];

  tea: any[];

  keg: any[];

  otherFilters: any[];

  qb_info:any;



  constructor() {
    this.initData();
  }

  initData() {
    this.organizationName = "";
    this.locationName = "";
    this.address = "";
    this.address2 = "";
    this.city = "";
    this.state = "";
    this.zip = "";
    this.phone = "";
    this.locationEmail = "";
    this.locationNotes = "";
    this.deliveryNotes = "";

    this.commission = "";
    this.shipping = "";

    this.paymentNotes = '';
    this.printNotes = '';


    this.brewers = [];
    this.grinders_brew = [];
    this.filters_brew = [];

    this.espresso = [];
    this.grinders_esp = [];
    this.filters_esp = [];

    this.water_tower = [];

    this.tea = [];

    this.keg = [];

    this.otherFilters = [];

    this.qb_info = {};
  }

  initWithFirebaseData(firebase) {
    console.log(firebase);
    this.initData();
    if (firebase.organizationName) this.organizationName = firebase.organizationName;
    if (firebase.locationName) this.locationName = firebase.locationName;
    if (firebase.address) this.address = firebase.address;
    if (firebase.address2) this.address2 = firebase.address2;
    if (firebase.city) this.city = firebase.city;
    if (firebase.state) this.state = firebase.state;
    if (firebase.zip) this.zip = firebase.zip;
    if (firebase.phone) this.phone = firebase.phone;

    if (firebase.shipping) this.shipping = firebase.shipping;
    if (firebase.commission) this.commission = firebase.commission;

    if (firebase.locationEmail) this.locationEmail = firebase.locationEmail;
    if (firebase.locationNotes) this.locationNotes = firebase.locationNotes;
    if (firebase.deliveryNotes) this.deliveryNotes = firebase.deliveryNotes;


    if (firebase.paymentNotes) this.paymentNotes = firebase.paymentNotes;
    if (firebase.printNotes) this.printNotes = firebase.printNotes;

    if (firebase.coffeeBrewers) this.brewers = firebase.coffeeBrewers;
    if (firebase.coffeeGrinders) this.grinders_brew = firebase.coffeeGrinders;
    if (firebase.coffeeFilters) this.filters_brew = firebase.coffeeFilters;

    if (firebase.espressoMachines) this.espresso = firebase.espressoMachines;
    if (firebase.espressoGrinders) this.grinders_esp = firebase.espressoGrinders;
    if (firebase.espressoFilters) this.filters_esp = firebase.espressoFilters;

    if (firebase.waterTowers) this.water_tower = firebase.waterTowers;

    if (firebase.teaBrewers) this.tea = firebase.teaBrewers;

    if (firebase.kegs) this.keg = firebase.kegs;

    if (firebase.otherFilters) this.otherFilters = firebase.otherFilters;

    if (firebase.qb_info) this.qb_info = firebase.qb_info;
  }
  getGeoCodeAddress() {
    var retVal = "";
    retVal =  this.address + ',' + this.city + ',' + this.state + ',' + this.zip;
    retVal = encodeURIComponent(retVal);
    return retVal;
  }
  getClientLocString() {
    var retVal = "";
    retVal = "<strong>"+this.address+"<br>"+
             this.city+", "+this.state+" "+this.zip+"</strong><br>"+
             this.phone;
    return retVal;
  }
  hasEquipment_private(category){
    return (category.length > 0);
  }
  hasEquipment(){
    var retVal = false;
    retVal = this.hasEquipment_private(this.brewers) ||
             this.hasEquipment_private(this.grinders_brew) ||
             this.hasEquipment_private(this.filters_brew) ||
             this.hasEquipment_private(this.espresso) ||
             this.hasEquipment_private(this.grinders_esp) ||
             this.hasEquipment_private(this.filters_esp) ||
             this.hasEquipment_private(this.water_tower) ||
             this.hasEquipment_private(this.tea) ||
             this.hasEquipment_private(this.keg) ||
             this.hasEquipment_private(this.otherFilters);
    return retVal;
  }
  getEquipmentTotalString(){
    var retVal = "";
    this.brewers.forEach(element => {
      retVal += element + ", ";
    });
    this.grinders_brew.forEach(element => {
      retVal += element + ", ";
    });
    this.filters_brew.forEach(element => {
      retVal += element + ", ";
    });
    this.espresso.forEach(element => {
      retVal += element + ", ";
    });
    this.grinders_esp.forEach(element => {
      retVal += element + ", ";
    });
    this.filters_esp.forEach(element => {
      retVal += element + ", ";
    });
    this.water_tower.forEach(element => {
      retVal += element + ", ";
    });
    this.tea.forEach(element => {
      retVal += element + ", ";
    });
    this.keg.forEach(element => {
      retVal += element + ", ";
    });
    this.otherFilters.forEach(element => {
      retVal += element + ", ";
    });
    return retVal;
  }
  getEquipmentTotal(){
    return this.brewers.length +
           this.grinders_brew.length +
           this.filters_brew.length +
           this.espresso.length +
           this.grinders_esp.length +
           this.filters_esp.length +
           this.water_tower.length +
           this.tea.length +
           this.keg.length +
           this.otherFilters.length;
  }
  getAllEquipment(){
    let allEquipment = [];
    return allEquipment.concat(this.brewers,this.grinders_brew,this.filters_brew,
                               this.espresso,this.grinders_esp,this.filters_esp,this.water_tower,
                               this.tea,this.keg,this.otherFilters);
  }
}
