// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    //  Real Sandbox
    apiKey: "AIzaSyB5voAoPGPqT5cJKqtLGtT1KVWmJ2Y-RgY",
    authDomain: "fazendademo.firebaseapp.com",
    databaseURL: "https://fazendademo.firebaseio.com",
    projectId: "fazendademo",
    storageBucket: "fazendademo.appspot.com",
    messagingSenderId: "301229277567"
  },
  mode: 'Production',
  sandbox: false,
  version: '4.22',
  qb: {
    url: 'https://fazenda-connect.firebaseapp.com',
    port: ''
  },
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
