import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'recent-orders',
    loadChildren: () => import('./pages/recent-orders/recent-orders.module').then( m => m.RecentOrdersPageModule)
  },
  {
    path: 'service-request',
    loadChildren: () => import('./pages/service-request/service-request.module').then( m => m.ServiceRequestPageModule)
  },
  {
    path: 'service-history',
    loadChildren: () => import('./pages/service-history/service-history.module').then( m => m.ServiceHistoryPageModule)
  },
  {
    path: 'request-tracker',
    loadChildren: () => import('./pages/request-tracker/request-tracker.module').then( m => m.RequestTrackerPageModule)
  },
  {
    path: 'create-order',
    loadChildren: () => import('./pages/create-order/create-order.module').then( m => m.CreateOrderPageModule)
  },
  {
    path: 'create-order/:location',
    loadChildren: () => import('./pages/create-order/create-order.module').then( m => m.CreateOrderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./pages/account/account.module').then( m => m.AccountPageModule)
  },  {
    path: 'org-select',
    loadChildren: () => import('./modules/org-select/org-select.module').then( m => m.OrgSelectPageModule)
  },
  {
    path: 'client-select',
    loadChildren: () => import('./modules/client-select/client-select.module').then( m => m.ClientSelectPageModule)
  },
  {
    path: 'problem-select',
    loadChildren: () => import('./modules/problem-select/problem-select.module').then( m => m.ProblemSelectPageModule)
  },
  {
    path: 'order-review',
    loadChildren: () => import('./modules/order-review/order-review.module').then( m => m.OrderReviewPageModule)
  },
  {
    path: 'delivery-tracker',
    loadChildren: () => import('./pages/delivery-tracker/delivery-tracker.module').then( m => m.DeliveryTrackerPageModule)
  },
  {
    path: 'client-management',
    loadChildren: () => import('./pages/client-management/client-management.module').then( m => m.ClientManagementPageModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./pages/calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'analytics',
    loadChildren: () => import('./pages/analytics/analytics.module').then( m => m.AnalyticsPageModule)
  },
  {
    path: 'order-management',
    loadChildren: () => import('./pages/order-management/order-management.module').then( m => m.OrderManagementPageModule)
  },
  {
    path: 'request-management',
    loadChildren: () => import('./pages/request-management/request-management.module').then( m => m.RequestManagementPageModule)
  },
  {
    path: 'order-details',
    loadChildren: () => import('./pages/order-details/order-details.module').then( m => m.OrderDetailsPageModule)
  },
  {
    path: 'order-details/:orderId',
    loadChildren: () => import('./pages/order-details/order-details.module').then( m => m.OrderDetailsPageModule)
  },
  {
    path: 'request-details',
    loadChildren: () => import('./pages/request-details/request-details.module').then( m => m.RequestDetailsPageModule)
  },
  {
    path: 'request-details/:requestId',
    loadChildren: () => import('./pages/request-details/request-details.module').then( m => m.RequestDetailsPageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule)
  },
  {
    path: 'equipment',
    loadChildren: () => import('./pages/equipment/equipment.module').then( m => m.EquipmentPageModule)
  },
  {
    path: 'quickbooks-connect',
    loadChildren: () => import('./pages/quickbooks-connect/quickbooks-connect.module').then( m => m.QuickbooksConnectPageModule)
  },
  {
    path: 'qb-client-select',
    loadChildren: () => import('./modules/qb-client-select/qb-client-select.module').then( m => m.QbClientSelectPageModule)
  },
  {
    path: 'qb-item-select',
    loadChildren: () => import('./modules/qb-item-select/qb-item-select.module').then( m => m.QbItemSelectPageModule)
  },
  {
    path: 'product-select',
    loadChildren: () => import('./modules/product-select/product-select.module').then( m => m.ProductSelectPageModule)
  },
  {
    path: 'part-select',
    loadChildren: () => import('./modules/part-select/part-select.module').then( m => m.PartSelectPageModule)
  },
  {
    path: 'issue-select',
    loadChildren: () => import('./modules/issue-select/issue-select.module').then( m => m.IssueSelectPageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/map/map.module').then( m => m.MapPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
