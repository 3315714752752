// Angular imports
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Firebase models
import { OrderData, ProductFields } from 'src/legacy/classes/OrderData';

// Config
import { Subscription, Observable } from 'rxjs';

import {environment} from 'src/environments/environment';

interface QbAuthStatus {
  authenticated: boolean;
}

interface QbRef {
  name?: string;
  value: string;
}

interface QbOrderLineDetail {
  itemRef: QbRef;
  qty: number;
}

interface QbOrderLine {
  amount: number;
  description?: string;
  detailType: string;
  salesItemLineDetail: QbOrderLineDetail;
}

interface QbOrder {
  line: QbOrderLine[];
  customerRef: QbRef;
  customerMemo?: QBMemoRef;
  //salesTermRef?: QbRef;
}

interface QBMemoRef{
  value: string;
}

interface QbOrderResponse {
  success: boolean;
  purchase: QbOrder;
}

/*
  Generated class for the QuickbooksProvider provider.

  Handles communication with the Fazenda QuickBooks server.
*/
@Injectable()
export class QuickbooksProvider {
  qbApiTarget: string = null;
  authenticated: boolean = false;

  constructor(public http: HttpClient) {
    this.qbApiTarget = `${environment.qb.url}`;
    if (environment['qb'].port) {
      this.qbApiTarget = `${environment.qb.url}:${environment.qb.port}`;
    }
    this.checkAuthentication();
  }

  private checkAuthentication() {
    // Checks if Fazenda Quickbooks service has a valid token
    return this.http
      .get<QbAuthStatus>(`${this.qbApiTarget}/authenticated`)
      .subscribe(response => {
        this.authenticated = response['authenticated'];
      },
      error => {
        this.authenticated = false;
      });
  }

  public connectToQB() {
    // Opens OAuth window, once closed will check to see if auth was successful
    var win = window.open(`${this.qbApiTarget}/start`, '_blank', 'location=no');
    var timer = setInterval((function(this) {
        if(win.closed) {
            clearInterval(timer);
            this.checkAuthentication();
        }
    }).bind(this), 1000);
  }

  public setLocationOrder(orderData: OrderData): Promise<QbOrderResponse> {
    // Attempts to submit an order via the Fazenda QuickBooks server.
    // Returns true if submissions was successful, false otherwise.
    var invoice = this.createQbOrder(orderData);
    var endPoint = 'submit-order-new';
    //If orderData was QB info, use submit-order-new
    if(Object.keys(orderData.locationQBdata).length === 0 && orderData.locationQBdata.constructor === Object){
      endPoint = 'submit-order';
    }
    return this.http
      .post<QbOrderResponse>(`${this.qbApiTarget}/${endPoint}`, invoice)
      .toPromise()
      .then(response => {
        return response;
      })
      .catch(err => {
        //return false;
        return <QbOrderResponse> {success:false,purchase:<QbOrder>err};
      });
  }

  private createQbOrder(orderData: OrderData) {
    // Creates a new order according to the QbOrder interface
    let orders = [];
    // Flatten the orders and add product type to each
    ProductFields
      .filter(field => orderData[field].length)
      .map(field => {
        orderData[field].map(product => product['product'] = field);
        return orderData[field];
      })
      .map(field => { orders.push(...field) });

    // TODO: Populate actual unit price
    orders
      .map(order => order['price'] = +order['price']);
    //orders
      //.map(order => order['amount'] = +order['amount']);
    orders
      .map(order => order['quantity'] = +order['quantity']);
    let orderCommission = orderData.commission?orderData.commission:'';
    orders
      .map(order => order['commission'] =
           (order.product === 'coffee' ||
            order.product === 'decaf' ||
            order.product === 'espresso' ||
            order.product === 'espressoDecaf' ||
            order.product === 'tea' ||
            order.product === 'retail_coffee' ||
            order.product === 'retail_tea' ||
            order.product === 'liquids')?orderCommission:'');

    orders
      .map(order => order['qbId'] = order['qb_info']?order.qb_info.id:'');

    let custorerRef_Id = orderData.locationQBdata.id;
    let qb_billEmail = orderData.locationQBdata.email;
    let qb_terms = orderData.locationQBdata.terms;
    let legacy = false;
    let qb_trackingNumber = (orderData.orderRef!=='')?orderData.orderRef:'';

    //Does not seem to fix problem,
    //Customer tracking numbers being too long
    //for the firebase database, ie customer uses field as a comment
    if (qb_trackingNumber.length > 31) {
      qb_trackingNumber = qb_trackingNumber.substring(0, 30);
    }

    //if the set was empty, apply defaults
    if(Object.keys(orderData.locationQBdata).length === 0 && orderData.locationQBdata.constructor === Object){
      legacy = true;
      custorerRef_Id = orderData.locationName;
      qb_billEmail = '';
      qb_terms = '7'; //PRoduction 'net 30' is '7'
    }

    return <QbOrder> {
            line: orders.map(order => {
              let product_id = legacy?order.sku:order.qbId;
              let product_tax = 'NON';
              if(order.qb_info && order.qb_info.tax === true){
                product_tax = 'TAX'; //'TAX';
              }
              //product_tax = '7'; //Hard code to 'MA Sales Tax'
              let amount = (order.quantity * order.price);
              if(!amount) amount = 0; //default to 0 if no price is given
              return {
                amount: (order.quantity * order.price),
                //amount: 0,
                description: `${order.name} ${order.label}`,
                detailType: 'SalesItemLineDetail',
                salesItemLineDetail: {
                  itemRef: {
                    value: product_id  //order.qbId  //order.sku
                  },
                  qty: order.quantity,
                  unitPrice: order.price,
                  classRef: {
                    value: order.commission
                  },
                  taxCodeRef: {
                    value: product_tax
                  },
                }
              }
            }),
            customerRef: {
              value: custorerRef_Id // custorerRef_Id //orderData.locationName
            },
            salesTermRef: {
              value: qb_terms
            },
            billEmail: {
              address: qb_billEmail
            },
            trackingNum: qb_trackingNumber,
            allowOnlineCreditCardPayment: true,
            allowOnlineACHPayment: true,
            customerMemo: {
              //value: 'This is a customer note'
              //value: `[Ref:${orderData.orderRef}] ${orderData.notes}`
              value: 'Thank you for your business.'
            }
          }
  }
}
