import {ClientData} from "./ClientData";
import { environment } from 'src/environments/environment';

export enum OrderState {
   Received = 1,

   Processing,
   Filled,
   Shipped,
   Archived,
   DeliveryOnly,
}
export interface DeliveryCnt{
  box:string,
  tote:string,
  original:string,
  nitro:string,
  concentrate:string
}

// Each of the product categories
var ProductFields = [
  'coffee',
  'decaf',
  'coldBrew',
  'espresso',
  'espressoDecaf',
  'tea',
  'retail_coffee',
  'retail_tea',
  'filters',
  'other',
  'liquids',
  'shippingQB'
];

var ProductInventoryFields = [
  'coffeeProducts',
  'decafProducts',
  'coldBrewProducts',
  'espressoProducts',
  'espressoDecafProducts',
  'teaProducts',
  'retail_coffeeProducts',
  'retail_teaProducts',
  'filtersProducts',
  'otherProducts',
  'liquidsProducts'
];

export { ProductFields };

export class OrderData {
  firebaseKey: string;
  orderId: string;
  appVersion: string;
  rawInput: string;
  barcode: string; //future use
  weightDebug: string;

  name: string;
  phone: string;
  email: string;

  organizationName: string;
  locationName: string;
  locationAddress: string;
  locationAddress2: string;
  locationCity: string;
  locationState: string;
  locationZip: string;
  locationPhone: string;
  locationNotes: string;
  locationDeliveryNotes: string;

  locationPrintNotes: string;
  locationPaymentNotes: string;

  locationQBdata: any;

  orderRef: string;
  orderDate: string;
  orderState: OrderState;
  archived: boolean;
  notes: string;

  shippingStr:string;
  commission:string;

  coffee: any[];
  coffeeProducts: any[];
  coffeePar:any;
  coldBrew: any[];
  coldBrewProducts: any[];
  coldBrewPar:any;
  decaf: any[];
  decafProducts: any[];
  decafPar:any;
  espresso: any[];
  espressoProducts: any[];
  espressoPar:any;
  espressoDecaf: any[];
  espressoDecafProducts: any[];
  espressoDecafPar:any;

  filters: any[];
  filterProducts: any[];
  filterPar:any;
  tea: any[];
  teaProducts: any[];
  teaPar:any;
  retail_coffee: any[];
  retail_coffeeProducts: any[];
  retail_coffeePar:any;
  retail_tea: any[];
  retail_teaProducts: any[];
  retail_teaPar:any;
  liquids: any[];
  liquidProducts: any[];
  liquidPar:any;
  other: any[];
  otherProducts: any[];
  otherPar:any;

  promoProducts:any[];
  shippingQB:any[];

  modifyOrder:boolean = false;
  submitTitle:string = 'Submit';

  printStatus:string = '';

  deliveryComplete:boolean = false;
  deliveryDate:string;
  deliveryMethod:string;
  deliveryCnt:DeliveryCnt;
  deliveryNotes:any;


  constructor() {
    this.firebaseKey = "";
    this.orderId = "";
    this.appVersion = "1.0";
    this.rawInput = "";
    this.weightDebug = "";

    this.name = "";
    this.phone = "";
    this.email = "";

    this.organizationName = "";
    this.locationName = "Fazenda Coffee";
    this.locationAddress = "502 sprague street";
    this.locationAddress2 = "";
    this.locationCity = "Dedham";
    this.locationState = "MA";
    this.locationZip = "02067";
    this.locationPhone = "(617)-787-5282";
    this.locationNotes = "";
    this.locationDeliveryNotes = "";

    this.locationPrintNotes = '';
    this.locationPaymentNotes = '';

    this.locationQBdata = {};

    this.orderRef = "";
    this.orderDate = "";
    this.orderState = OrderState.Received;
    this.archived = false;
    this.notes = "";

    this.shippingStr= "";
    this.commission= "";

    this.printStatus = "";

    this.deliveryComplete = false;
    this.deliveryDate = "";
    this.deliveryMethod = "";
    this.deliveryCnt = {box:'',tote:'',original:'',nitro:'',concentrate:''};
    this.deliveryNotes = "";


    //Actual order submitted to firebase
    this.coffee = [];
    this.coldBrew = [];
    this.decaf = [];
    this.espresso = [];
    this.espressoDecaf = [];
    this.tea = [];
    this.filters = [];
    this.other = [];
    this.liquids = [];
    this.retail_coffee = [];
    this.retail_tea = [];
    this.shippingQB = [];

    //Order products including the varying quantity
    this.coffeeProducts = [];
    this.coldBrewProducts = [];
    this.decafProducts = [];
    this.espressoProducts = [];
    this.espressoDecafProducts = [];
    this.liquidProducts = [];
    this.teaProducts = [];
    this.otherProducts = [];
    this.filterProducts = [];
    this.retail_coffeeProducts = [];
    this.retail_teaProducts = [];
    this.promoProducts = [];

    this.coffeePar = {'parEnabled':false};
    this.decafPar = {'parEnabled':false};
    this.espressoPar = {'parEnabled':false};
    this.espressoDecafPar = {'parEnabled':false};
    this.coldBrewPar = {'parEnabled':false};
    this.teaPar = {'parEnabled':false};
    this.retail_coffeePar = {'parEnabled':false};
    this.retail_teaPar = {'parEnabled':false};
    this.liquidPar = {'parEnabled':false};
    this.filterPar = {'parEnabled':false};
    this.otherPar = {'parEnabled':false};

  }
  getInternalRef(){
    var pathId = this.firebaseKey; // The ID in the Path.
    var orderRef = pathId.substr(pathId.length - 6, pathId.length);
    return orderRef;
  }
  getImportName(){
    var retVal = "NONE";
    if(Object.keys(this.locationQBdata).length !== 0 && this.locationQBdata.constructor === Object){
      retVal = this.locationQBdata.name;
    }
    return retVal;
  }
  getAllProducts(){
    var allProducts = [];
    return allProducts.concat(this.coffeeProducts,this.coldBrewProducts,this.decafProducts,
                            this.espressoProducts,this.espressoDecafProducts,this.liquidProducts,
                            this.teaProducts,this.otherProducts,this.filterProducts,
                            this.retail_coffeeProducts,this.retail_teaProducts,this.promoProducts);
  }
   applyOrderToProducts_private(productsOrder:any[],productsInventory:any[]){
     for(let product of productsInventory){
       for(let p of productsOrder){
         if(product['name']==p['name']){
           //If par order product, subtract from par to display appropriately
           var quantity = p['quantity'];
           if(product['parOrder']){
             quantity = product['parOrder'] - quantity;
           }
           product['quantity'] = quantity;
         }
       }
     }
   }
  //Used when copying a recent order to the current product input
  applyOrderToProducts(){
    this.applyOrderToProducts_private(this.coffee,this.coffeeProducts);
    this.applyOrderToProducts_private(this.coldBrew,this.coldBrewProducts);
    this.applyOrderToProducts_private(this.decaf,this.decafProducts);
    this.applyOrderToProducts_private(this.espresso,this.espressoProducts);
    this.applyOrderToProducts_private(this.espressoDecaf,this.espressoDecafProducts);
    this.applyOrderToProducts_private(this.tea,this.teaProducts);
    this.applyOrderToProducts_private(this.filters,this.filterProducts);
    this.applyOrderToProducts_private(this.liquids,this.liquidProducts);
    this.applyOrderToProducts_private(this.other,this.otherProducts);
    this.applyOrderToProducts_private(this.retail_coffee,this.retail_coffeeProducts);
    this.applyOrderToProducts_private(this.retail_tea,this.retail_teaProducts);
    this.applyOrderToProducts_private(this.other,this.promoProducts); //Apply the promo to the 'other category'
  }
  addShipping(price){
    //liquids are the last in the list, add here so shipping is last
    this.shippingQB.push({name:"Shipping and Handling",
                        label:" ",
                        sku:"Freight Out",
                        quantity:1,
                        price:price,
                        qb_info:{
                          "desc" : "Shipping & Handling",
                          "id" : environment['sandbox']?"7":"69", //69- Prodcution, 7-Sandbox
                          "name" : "Freight Out",
                          "sku" : "Freight Out",
                          "tax" : false
                        }
                    });
  }
  checkShippingRules_private(productesOrder:any[]){
    let retVal = false;
    for (let product of productesOrder){
        if(product.shipping && product.shipping !== ''){
          retVal = true;
          this.shippingStr = product.shipping;
          break;
        }
    }
    return retVal;
  }
  checkShippingRules(){
    if(! this.checkShippingRules_private(this.coffeeProducts)){
      if(! this.checkShippingRules_private(this.coldBrewProducts)){
        if(! this.checkShippingRules_private(this.decafProducts)){
          if(! this.checkShippingRules_private(this.espressoProducts)){
            if(! this.checkShippingRules_private(this.espressoDecafProducts)){
              if(! this.checkShippingRules_private(this.teaProducts)){
                if(! this.checkShippingRules_private(this.filterProducts)){
                  if(! this.checkShippingRules_private(this.liquidProducts)){
                    if(! this.checkShippingRules_private(this.otherProducts)){
                      if(! this.checkShippingRules_private(this.retail_coffeeProducts)){
                        if(! this.checkShippingRules_private(this.retail_teaProducts)){
                          console.log("No shipping rules found.");
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  getNitroCount(){
    var count = 0;
    for (let product of this.liquids){
      if((product['sku'] === '76815' || product['sku'] === '76812' || product['sku'] === '76811' || product['sku'] === '76810') && product['quantity']>0){
        count += product['quantity'];
      }
    }
    return count;
  }
  getConcentrateCount(){
    var count = 0;
    for (let product of this.liquids){
      if((product['sku'] === '76805' || product['sku'] === '76808' || product['sku'] === '76809' ) && product['quantity']>0){
        count += product['quantity'];
      }
    }
    return count;
  }
  getCaseCount(){
    var count = 0;
    for (let product of this.liquids){
      if((product['sku'] === '76811' || product['sku'] === '76810') && product['quantity']>0){
        count += product['quantity'];
      }
    }
    return count;
  }
  getBottleCount(){
    var count = 0;
    for (let product of this.liquids){
      if((product['sku'] === '76808' || product['sku'] === '76809') && product['quantity']>0){
        count += product['quantity'];
      }
    }
    return count;
  }
  getKegCount(){
    var count = 0;
    for (let product of this.liquids){
      if(product['name'].toLowerCase().includes('keg') && product['quantity']>0){
        count += product['quantity'];
      }
    }
    return count;
  }
  applyShippingRules(newOrder:OrderData){
    //Always,Never,Below 30lbs,Below 40lbs,Less than 3 cases,Less than 12 bottles,Less than 6 kegs
    //Calculate Shipping here
    if(this.shippingStr === "Always"){
      newOrder.addShipping(10);
    }
    else if(this.shippingStr === "Always $15"){
      newOrder.addShipping(15);
    }
    else if(this.shippingStr === "Always $25"){
      newOrder.addShipping(25);
    }
    else if(this.shippingStr === "Below 20lbs"){
      if(newOrder.getWeight()<20){
        newOrder.addShipping(10);
      }
    }
    else if(this.shippingStr === "Below 30lbs"){
      if(newOrder.getWeight()<30){
        newOrder.addShipping(10);
      }
    }
    else if(this.shippingStr === "Below 40lbs"){
      if(newOrder.getWeight()<40){
        newOrder.addShipping(10);
      }
    }
    else if(this.shippingStr === "Less than 3 cases"){
      if(newOrder.getCaseCount()<3){
        newOrder.addShipping(10);
      }
    }
    else if(this.shippingStr === "Less than 12 bottles"){
      if(newOrder.getBottleCount()<12){
        newOrder.addShipping(10);
      }
    }
    else if(this.shippingStr === "Less than 6 kegs"){
      if(newOrder.getKegCount()<6){
        newOrder.addShipping(10);
      }
    }
  }
  createQbCopy(){
    var newOrder = new OrderData();
    newOrder.locationName = this.locationName;
    newOrder.commission = this.commission;
    newOrder.orderRef = this.orderRef;

    newOrder.coffee = this.coffee.slice(0);
    newOrder.coldBrew = this.coldBrew.slice(0);
    newOrder.decaf = this.decaf.slice(0);
    newOrder.espresso = this.espresso.slice(0);
    newOrder.espressoDecaf = this.espressoDecaf.slice(0);
    newOrder.tea = this.tea.slice(0);
    newOrder.filters = this.filters.slice(0);
    newOrder.other = this.other.slice(0);
    newOrder.liquids = this.liquids.slice(0);
    newOrder.retail_coffee = this.retail_coffee.slice(0);
    newOrder.retail_tea = this.retail_tea.slice(0);

    newOrder.locationQBdata = this.locationQBdata; //Should this be a copy?
    this.applyShippingRules(newOrder);
    return newOrder;
  }
  copyOverProductsFromRecent(recent:OrderData,modify:boolean){
    this.coffeeProducts = recent.coffeeProducts;
    this.coffeePar = recent.coffeePar;
    this.coldBrewProducts = recent.coldBrewProducts;
    this.coldBrewPar = recent.coldBrewPar;
    this.decafProducts = recent.decafProducts;
    this.decafPar = recent.decafPar;
    this.espressoProducts = recent.espressoProducts;
    this.espressoPar = recent.espressoPar;
    this.espressoDecafProducts = recent.espressoDecafProducts;
    this.espressoDecafPar = recent.espressoDecafPar;
    this.teaProducts = recent.teaProducts;
    this.teaPar = recent.teaPar;
    this.liquidProducts = recent.liquidProducts;
    this.liquidPar = recent.liquidPar;
    this.otherProducts = recent.otherProducts;
    this.otherPar = recent.otherPar;
    this.filterProducts = recent.filterProducts;
    this.filterPar = recent.filterPar;
    this.retail_coffeeProducts = recent.retail_coffeeProducts;
    this.retail_coffeePar = recent.retail_coffeePar;
    this.retail_teaProducts = recent.retail_teaProducts;
    this.retail_teaPar = recent.retail_teaPar;
    //No need to reset the promotional product category
    //Order data has already been copied over at this point
    if(modify){
      this.modifyOrder = true;
      this.submitTitle = 'Update'
    }
    else{
      this.modifyOrder = false;
      this.submitTitle = 'Submit'
      //Reset all the non-order data
      this.orderRef = "";
      this.orderDate = "";
      this.orderState = OrderState.Received;
      this.archived = false;
      this.notes = "";
      this.orderId = "";
    }
  }
  resetProductOrder_private(productsInventory:any[]){
    for(let product of productsInventory){
      product['quantity'] = '';
    }
  }

  resetProductOrder(){
    this.modifyOrder = false;
    this.submitTitle = 'Submit'
    this.orderRef = "";
    this.orderDate = "";
    this.orderState = OrderState.Received;
    this.archived = false;
    this.notes = "";
    this.rawInput = "";
    this.weightDebug = "";
    //Clear the quantities
    this.resetProductOrder_private(this.coffeeProducts);
    this.resetProductOrder_private(this.coldBrewProducts);
    this.resetProductOrder_private(this.decafProducts);
    this.resetProductOrder_private(this.espressoProducts);
    this.resetProductOrder_private(this.espressoDecafProducts);

    this.resetProductOrder_private(this.teaProducts);
    this.resetProductOrder_private(this.filterProducts);
    this.resetProductOrder_private(this.liquidProducts);
    this.resetProductOrder_private(this.otherProducts);
    this.resetProductOrder_private(this.retail_coffeeProducts);
    this.resetProductOrder_private(this.retail_teaProducts);

    this.resetProductOrder_private(this.promoProducts);
  }

  clearOrder(){
    this.coffee = [];
    this.coldBrew = [];
    this.decaf = [];
    this.espresso = [];
    this.espressoDecaf = [];
    this.liquids = [];
    this.tea = [];
    this.filters = [];
    this.other = [];
    this.retail_coffee = [];
    this.retail_tea = [];
    this.rawInput = "";
    this.weightDebug = "";
  }
  clearDelivery(){
    this.printStatus = "";

    this.deliveryComplete = false;
    this.deliveryDate = "";
    this.deliveryMethod = "";
    this.deliveryCnt = {box:'',tote:'',original:'',nitro:'',concentrate:''};
    this.deliveryNotes = "";
  }

  isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

  deleteItembySKU_private(sku,products:any[]){
    return products.filter(function(product, index, arr){
      return (product.sku !== sku);
    });
    /*
    for (let product of products){
      if(product.sku == sku){

      }
    }
    */
  }
  deleteItemBySKU(sku){
    //Attemps to remove from each category, more efficient would be to stop once we found the item
    this.coffee = this.deleteItembySKU_private(sku, this.coffee);
    this.decaf = this.deleteItembySKU_private(sku, this.decaf);
    this.espresso = this.deleteItembySKU_private(sku, this.espresso);
    this.espressoDecaf = this.deleteItembySKU_private(sku, this.espressoDecaf);
    this.tea = this.deleteItembySKU_private(sku, this.tea);
    this.coldBrew = this.deleteItembySKU_private(sku, this.coldBrew);
    this.retail_coffee = this.deleteItembySKU_private(sku, this.retail_coffee);
    this.retail_tea = this.deleteItembySKU_private(sku, this.retail_tea);
    this.filters = this.deleteItembySKU_private(sku, this.filters);
    this.other = this.deleteItembySKU_private(sku, this.other);
    this.liquids = this.deleteItembySKU_private(sku, this.liquids);
  }
  updateItembySKU_private(sku,q,products:any[]){
    products.forEach(p => {
      if(p.sku === sku){
        p.quantity = q;
      }
    });
  }
  updateItemBySKU(sku,quantity){
    this.updateItembySKU_private(sku, quantity, this.coffee);
    this.updateItembySKU_private(sku, quantity, this.decaf);
    this.updateItembySKU_private(sku, quantity, this.espresso);
    this.updateItembySKU_private(sku, quantity, this.espressoDecaf);
    this.updateItembySKU_private(sku, quantity, this.tea);
    this.updateItembySKU_private(sku, quantity, this.coldBrew);
    this.updateItembySKU_private(sku, quantity, this.retail_coffee);
    this.updateItembySKU_private(sku, quantity, this.retail_tea);
    this.updateItembySKU_private(sku, quantity, this.filters);
    this.updateItembySKU_private(sku, quantity, this.other);
    this.updateItembySKU_private(sku, quantity, this.liquids);
  }
  convertToOrder_private(productsOrder:any[],productsInventory:any[]){
    for (let product of productsInventory){
      var quantityInput = product['quantity'];
      if(this.isNumber(quantityInput) && quantityInput>=0){
        //If par order product, subtract from par to display appropriately
        if(product['parOrder']){
          //Add the par input to the debug string before recalculation
          this.rawInput += quantityInput +" - "+product['name'] +", ";
          quantityInput = product['parOrder'] - quantityInput;
        }
        //Update the price if thelocatoin  has an override
        var price = product['unit_price'];
        if(product['locationPrice']){
          price = product['locationPrice'];
        }
        //Include any notes about the weight
        var weightNotes = '';
        if(product['notes']){
          weightNotes = product['notes'];
        }

        let qb_info = product['qb_info']?product['qb_info']:{};
        //Filter out negative and non zero values
        if(quantityInput>0){
          productsOrder.push({name:product['name'],
                              quantity:quantityInput,
                              label:product['desc'],
                              sku:product['sku'],
                              notes:weightNotes,
                              price:price,
                              qb_info:qb_info});
        }
      }
    }
  }

  createOrderProduct(product){
    var price = product['unit_price'];
    if(product['locationPrice']){
      price = product['locationPrice'];
    }
    //Include any notes about the weight
    var weightNotes = '';
    if(product['notes']){
      weightNotes = product['notes'];
    }

    let qb_info = product['qb_info']?product['qb_info']:{};
    //Filter out negative and non zero values
    return {name:product['name'],
            quantity:0,
            label:product['desc'],
            sku:product['sku'],
            notes:weightNotes,
            price:price,
            product:product['product'],
            qb_info:qb_info};
  }

  convertToOrder(){
    this.clearOrder();
    this.convertToOrder_private(this.coffee,this.coffeeProducts);
    this.convertToOrder_private(this.decaf,this.decafProducts);
    this.convertToOrder_private(this.espresso,this.espressoProducts);
    this.convertToOrder_private(this.espressoDecaf,this.espressoDecafProducts);
    this.convertToOrder_private(this.tea,this.teaProducts);
    this.convertToOrder_private(this.coldBrew,this.coldBrewProducts);
    this.convertToOrder_private(this.retail_coffee,this.retail_coffeeProducts);
    this.convertToOrder_private(this.retail_tea,this.retail_teaProducts);
    this.convertToOrder_private(this.filters,this.filterProducts);
    this.convertToOrder_private(this.other,this.otherProducts);
    this.convertToOrder_private(this.other,this.promoProducts);
    this.convertToOrder_private(this.liquids,this.liquidProducts);
    if(this.rawInput != ''){
      this.rawInput += '<br>';
    }
    this.rawInput += 'Shipping Rules: '+this.shippingStr;
    this.rawInput += '<br>QB Import: '+this.getImportName();
    this.rawInput += '<br>Est. Price: $'+this.getTotalPrice().toFixed(2);
    this.rawInput += '<br>Calculated Weight: '+this.getProductWeight() + ' lbs.';
    if(this.weightDebug!==''){
      this.rawInput += '<br>Products without weight: '+this.weightDebug;
    }
    //Auto Delivery Rules
    if(this.locationDeliveryNotes.toLowerCase().includes("ups")){
      this.deliveryMethod = "UPS";
    }
    this.deliveryCnt.nitro = this.getNitroCount().toString();
    this.deliveryCnt.concentrate = this.getConcentrateCount().toString();
  }
  getOrderSummary_Array_private(productsOrder:any[]){
    var summary = [];
    for (let product of productsOrder){
      if(product['quantity']>0){
        let s = '('+product['quantity']+'x) '+product['name'];
        summary.push(s);
      }
    }
    return summary;
  }
  getOrderSummary_Array(){
    var summary = [];
    summary = summary.concat(this.getOrderSummary_Array_private(this.coffee));
    summary = summary.concat(this.getOrderSummary_Array_private(this.decaf));
    summary = summary.concat(this.getOrderSummary_Array_private(this.espresso));
    summary = summary.concat(this.getOrderSummary_Array_private(this.espressoDecaf));
    summary = summary.concat(this.getOrderSummary_Array_private(this.coldBrew));
    summary = summary.concat(this.getOrderSummary_Array_private(this.liquids));
    summary = summary.concat(this.getOrderSummary_Array_private(this.tea));
    summary = summary.concat(this.getOrderSummary_Array_private(this.retail_coffee));
    summary = summary.concat(this.getOrderSummary_Array_private(this.retail_tea));
    summary = summary.concat(this.getOrderSummary_Array_private(this.filters));
    summary = summary.concat(this.getOrderSummary_Array_private(this.other));
    //remove empty elements
    //console.log(summary);
    return summary.filter(s=>s.length!=0);
    //return summary;
  }
  getOrderSummary_private(productsOrder:any[]){
    var summary = '';
    for (let product of productsOrder){
      if(product['quantity']>0){
        summary += '('+product['quantity']+'x) '+product['name']+"- ";
        //summary += product['name']+", ";
      }
    }
    //TODO: Line breaks do not work with IONIC 2
    //if(summary != ''){
      //summary += "<br/>"
    //}
    return summary;
  }
  getOrderSummary(){
    var summary = "";
    summary += this.getOrderSummary_private(this.coffee);
    summary += this.getOrderSummary_private(this.decaf);
    summary += this.getOrderSummary_private(this.espresso);
    summary += this.getOrderSummary_private(this.espressoDecaf);
    summary += this.getOrderSummary_private(this.coldBrew);
    summary += this.getOrderSummary_private(this.liquids);
    summary += this.getOrderSummary_private(this.tea);
    summary += this.getOrderSummary_private(this.retail_coffee);
    summary += this.getOrderSummary_private(this.retail_tea);
    summary += this.getOrderSummary_private(this.filters);
    summary += this.getOrderSummary_private(this.other);

    //Remove Trailing Comma
    summary = summary.substr(0,summary.length-2);
    return summary;
  }
  getOrderSummaryConfirm_private(productsOrder:any[]){
    var summary = '';
    for (let product of productsOrder){
      if(product['quantity']>0){
        summary += '('+product['quantity']+'x) '+product['name']+"<br>";
        //summary += product['name']+", ";
      }
    }
    return summary;
  }
  getOrderSummary_Confirm(){
    var summary = "";
    summary += this.getOrderSummaryConfirm_private(this.coffee);
    summary += this.getOrderSummaryConfirm_private(this.decaf);
    summary += this.getOrderSummaryConfirm_private(this.espresso);
    summary += this.getOrderSummaryConfirm_private(this.espressoDecaf);
    summary += this.getOrderSummaryConfirm_private(this.coldBrew);
    summary += this.getOrderSummaryConfirm_private(this.liquids);
    summary += this.getOrderSummaryConfirm_private(this.tea);
    summary += this.getOrderSummaryConfirm_private(this.retail_coffee);
    summary += this.getOrderSummaryConfirm_private(this.retail_tea);
    summary += this.getOrderSummaryConfirm_private(this.filters);
    summary += this.getOrderSummaryConfirm_private(this.other);
    //Remove Trailing Comma
    //summary = summary.substr(0,summary.length-2);
    return summary;
  }
  getItemTotal_private(productsOrder:any[]){
    var total = 0;
    for (let product of productsOrder){
      total += Number(product['quantity']);
    }
    return total;
  }
  getItemTotal(){
    var itemTotal = 0;
    itemTotal += this.getItemTotal_private(this.coffee);
    itemTotal += this.getItemTotal_private(this.coldBrew);
    itemTotal += this.getItemTotal_private(this.decaf);
    itemTotal += this.getItemTotal_private(this.espresso);
    itemTotal += this.getItemTotal_private(this.espressoDecaf);
    itemTotal += this.getItemTotal_private(this.tea);
    itemTotal += this.getItemTotal_private(this.filters);
    itemTotal += this.getItemTotal_private(this.other);
    itemTotal += this.getItemTotal_private(this.liquids);
    itemTotal += this.getItemTotal_private(this.retail_coffee);
    itemTotal += this.getItemTotal_private(this.retail_tea);
    return itemTotal;
  }
  //Returns the total different number of products (ignores quantity)
  getProductTotal_private(productsOrder:any[]){
    var total = 0;
    for (let product of productsOrder){
      if(product['quantity']>0){
        total += 1;
      }
    }
    return total;
  }

  getProductTotal(){
    var itemTotal = 0;
    itemTotal += this.getProductTotal_private(this.coffeeProducts);
    itemTotal += this.getProductTotal_private(this.decafProducts);
    itemTotal += this.getProductTotal_private(this.coldBrewProducts);
    itemTotal += this.getProductTotal_private(this.espressoProducts);
    itemTotal += this.getProductTotal_private(this.espressoDecafProducts);
    itemTotal += this.getProductTotal_private(this.filterProducts);
    itemTotal += this.getProductTotal_private(this.liquidProducts);
    itemTotal += this.getProductTotal_private(this.otherProducts);
    itemTotal += this.getProductTotal_private(this.teaProducts);
    itemTotal += this.getProductTotal_private(this.retail_coffeeProducts);
    itemTotal += this.getProductTotal_private(this.retail_teaProducts);
    return itemTotal;
  }
  hasProducts_private(productsOrder:any[]){
    var hasP = false;
    if(productsOrder.length>0) hasP = true;
    return hasP;
  }
  hasProducts(){
    return (this.hasProducts_private(this.coffeeProducts) ||
            this.hasProducts_private(this.decafProducts) ||
            this.hasProducts_private(this.coldBrewProducts) ||
            this.hasProducts_private(this.espressoProducts) ||
            this.hasProducts_private(this.espressoDecafProducts) ||
            this.hasProducts_private(this.filterProducts) ||
            this.hasProducts_private(this.liquidProducts) ||
            this.hasProducts_private(this.otherProducts) ||
            this.hasProducts_private(this.retail_coffeeProducts) ||
            this.hasProducts_private(this.retail_teaProducts) ||
            this.hasProducts_private(this.teaProducts));
  }
  sortProducts_private(productsOrder:any[]){
    // temporary array holds objects with position and sort-value
    var mapped = productsOrder.map(function(el, i) {
      return { index: i, value: parseInt(el.order) };
    })

    // sorting the mapped array containing the reduced values
    mapped.sort(function(a, b) {
      return +(a.value > b.value) || +(a.value === b.value) - 1;
    });

    // container for the resulting order
    var result = mapped.map(function(el){
      return productsOrder[el.index];
    });

    return result;
  }
  sortProducts(){
    this.coffeeProducts = this.sortProducts_private(this.coffeeProducts);
    this.decafProducts = this.sortProducts_private(this.decafProducts);
    this.coldBrewProducts = this.sortProducts_private(this.coldBrewProducts);
    this.espressoProducts = this.sortProducts_private(this.espressoProducts);
    this.espressoDecafProducts = this.sortProducts_private(this.espressoDecafProducts);
    this.filterProducts = this.sortProducts_private(this.filterProducts);
    this.liquidProducts = this.sortProducts_private(this.liquidProducts);
    this.otherProducts = this.sortProducts_private(this.otherProducts);
    this.retail_coffeeProducts = this.sortProducts_private(this.retail_coffeeProducts);
    this.retail_teaProducts = this.sortProducts_private(this.retail_teaProducts);
    this.teaProducts = this.sortProducts_private(this.teaProducts);
  }
  isParEnabled(){
    return (this.coffeePar['parEnabled'] ||
            this.decafPar['parEnabled']  ||
            this.coldBrewPar['parEnabled']  ||
            this.espressoPar['parEnabled']  ||
            this.espressoDecafPar['parEnabled']  ||
            this.filterPar['parEnabled']  ||
            this.liquidPar['parEnabled']  ||
            this.retail_coffeePar['parEnabled']  ||
            this.retail_teaPar['parEnabled']  ||
            this.teaPar['parEnabled']  ||
            this.otherPar['parEnabled']);
  }
  getProductPrice_private(productsOrder:any[]){
    var totalPrice = 0;
    for (let product of productsOrder){
      var quantity = product['quantity'];
      if(this.isNumber(quantity) && quantity>=0){
        //Update the quantity
        if(product['parOrder']){
          quantity = product['parOrder'] - quantity;
        }
        //Update the price if the locatoin has an override
        var price = parseFloat(product['unit_price']);
        if(product['locationPrice']){
          price = parseFloat(product['locationPrice']);
        }
        totalPrice += quantity * price;
      }
    }
    return totalPrice;
  }
  getTotalPrice(){
    var totalPrice = 0;
    totalPrice += this.getProductPrice_private(this.coffeeProducts);
    totalPrice += this.getProductPrice_private(this.decafProducts);
    totalPrice += this.getProductPrice_private(this.espressoProducts);
    totalPrice += this.getProductPrice_private(this.espressoDecafProducts);
    totalPrice += this.getProductPrice_private(this.coldBrewProducts);
    totalPrice += this.getProductPrice_private(this.liquidProducts);
    totalPrice += this.getProductPrice_private(this.filterProducts);
    totalPrice += this.getProductPrice_private(this.teaProducts);
    totalPrice += this.getProductPrice_private(this.retail_coffeeProducts);
    totalPrice += this.getProductPrice_private(this.retail_teaProducts);
    totalPrice += this.getProductPrice_private(this.promoProducts);
    return totalPrice;
  }
  getProductOrderPrice_private(productsOrder:any[]){
    var totalPrice = 0;
    for (let product of productsOrder){
      var quantity = product['quantity'];
      if(this.isNumber(quantity) && quantity>=0){
        //Update the price if the locatoin has an override
        var price = parseFloat(product['price']);
        totalPrice += quantity * price;
      }
    }
    return totalPrice;
  }
  getTotalOrderPrice(){
    var totalPrice = 0;
    totalPrice += this.getProductOrderPrice_private(this.coffee);
    totalPrice += this.getProductOrderPrice_private(this.decaf);
    totalPrice += this.getProductOrderPrice_private(this.espresso);
    totalPrice += this.getProductOrderPrice_private(this.espressoDecaf);
    totalPrice += this.getProductOrderPrice_private(this.coldBrew);
    totalPrice += this.getProductOrderPrice_private(this.liquids);
    totalPrice += this.getProductOrderPrice_private(this.filters);
    totalPrice += this.getProductOrderPrice_private(this.tea);
    totalPrice += this.getProductOrderPrice_private(this.retail_coffee);
    totalPrice += this.getProductOrderPrice_private(this.retail_tea);
    return totalPrice;
  }
  getProductWeight_private(productsOrder:any[]){
    var weight = 0;
    for (let product of productsOrder){
      var quantity = product['quantity'];
      if(this.isNumber(quantity) && quantity>=0){
        if(product['parOrder']){
          quantity = product['parOrder'] - quantity;
        }
        if(product['notes'] && this.isNumber(product['notes'])){
          weight += quantity * parseFloat(product['notes']);
        }
        else{ //Use the sku to determine weight
          let skuID = parseInt(product['sku'].charAt(0));
          let skuWeight = parseInt(product['sku'].charAt(1));
          if(skuID === 7){ //Coffee, Espresso
            weight += skuWeight * quantity;
          }
          else{
            //Log items without a weight calculation to raw inout string.
            this.weightDebug += product['name']+", ";
          }
        }
      }
    }
    return weight;
  }
  getProductWeight(){
    var weightTotal = 0;
    weightTotal += this.getProductWeight_private(this.coffeeProducts);
    weightTotal += this.getProductWeight_private(this.decafProducts);
    weightTotal += this.getProductWeight_private(this.espressoProducts);
    weightTotal += this.getProductWeight_private(this.espressoDecafProducts);
    weightTotal += this.getProductWeight_private(this.coldBrewProducts);
    //weightTotal += this.getProductWeight_private(this.liquidProducts);
    weightTotal += this.getProductWeight_private(this.filterProducts);
    weightTotal += this.getProductWeight_private(this.teaProducts);
    weightTotal += this.getProductWeight_private(this.retail_coffeeProducts);
    weightTotal += this.getProductWeight_private(this.retail_teaProducts);
    weightTotal += this.getProductWeight_private(this.promoProducts);
    return weightTotal;
  }
  getWeight(){
    var weightTotal = 0;
    weightTotal += this.getProductWeight_private(this.coffee);
    weightTotal += this.getProductWeight_private(this.decaf);
    weightTotal += this.getProductWeight_private(this.espresso);
    weightTotal += this.getProductWeight_private(this.espressoDecaf);
    weightTotal += this.getProductWeight_private(this.coldBrew);
    //weightTotal += this.getProductWeight_private(this.liquids);
    weightTotal += this.getProductWeight_private(this.filters);
    weightTotal += this.getProductWeight_private(this.tea);
    weightTotal += this.getProductWeight_private(this.retail_coffee);
    weightTotal += this.getProductWeight_private(this.retail_tea);
    weightTotal += this.getProductWeight_private(this.other); //To catch the promotional products
    return weightTotal;
  }
  setupWithClientData(client: ClientData) {
    this.organizationName = client.organizationName;
    this.locationName = client.locationName;
    this.locationAddress = client.address;
    this.locationAddress2 = client.address2;
    this.locationCity = client.city;
    this.locationState = client.state;
    this.locationZip = client.zip;
    this.locationPhone = client.phone;
    this.locationNotes = client.locationNotes;
    this.locationDeliveryNotes = client.deliveryNotes;
    this.locationPrintNotes = client.printNotes;
    this.locationPaymentNotes = client.paymentNotes;
    if(client.commission === 'Yes'){
      this.commission = environment['sandbox']?"4300000000000227046":"3600000000001217082";
      //this.commission = '4300000000000227046'; //Sandbox
      //this.commission = '3600000000001217082'; //Production
    }
    if(client.qb_info) this.locationQBdata = client.qb_info;
  }
  getClientData() {
    let client = new ClientData();
    client.organizationName = this.organizationName;
    client.locationName = this.locationName;
    client.address = this.locationAddress;
    client.address2 = this.locationAddress2;
    client.city = this.locationCity;
    client.state = this.locationState;
    client.zip = this.locationZip;
    client.phone = this.locationPhone;
    client.locationNotes = this.locationNotes;
    client.deliveryNotes = this.locationDeliveryNotes;
    client.paymentNotes = this.locationPaymentNotes;
    client.printNotes = this.locationPrintNotes;
    client.shipping = this.shippingStr; //Keep for posterity
    client.commission = this.commission;
    return client;
  }
  initWithFirebase(firebaseData) {
    this.firebaseKey = firebaseData.key;
    this.orderId = firebaseData.orderId;
    this.appVersion = firebaseData.appVersion;
    if(firebaseData.rawInput) this.rawInput = firebaseData.rawInput;

    this.name = firebaseData.name;
    this.phone = firebaseData.phone;
    if(firebaseData.email) this.email = firebaseData.email;

    this.organizationName = firebaseData.organizationName;
    this.locationName = firebaseData.locationName;
    this.locationAddress = firebaseData.locationAddress;
    this.locationAddress2 = firebaseData.locationAddress2;
    this.locationCity = firebaseData.locationCity;
    this.locationState = firebaseData.locationState;
    this.locationZip = firebaseData.locationZip;
    this.locationPhone = firebaseData.locationPhone;
    if(firebaseData.locationNotes) this.locationNotes = firebaseData.locationNotes;
    if(firebaseData.locationDeliveryNotes) this.locationDeliveryNotes = firebaseData.locationDeliveryNotes;

    if(firebaseData.locationPaymentNotes) this.locationPaymentNotes = firebaseData.locationPaymentNotes;
    if(firebaseData.locationPrintNotes) this.locationPrintNotes = firebaseData.locationPrintNotes;

    this.orderRef = firebaseData.orderRef;
    this.orderDate = firebaseData.orderDate;
    this.orderState = firebaseData.orderState;
    this.archived = firebaseData.archived;
    this.notes = firebaseData.notes;

    if(firebaseData.shippingStr) this.shippingStr = firebaseData.shippingStr;
    if(firebaseData.shipping && firebaseData.shipping !== '') this.shippingStr = firebaseData.shipping;

    if(firebaseData.printStatus) this.printStatus = firebaseData.printStatus;

    if(firebaseData.commission) this.commission = firebaseData.commission;

    if(firebaseData.coffee) this.coffee = firebaseData.coffee;
    if(firebaseData.coldBrew) this.coldBrew = firebaseData.coldBrew;
    if(firebaseData.decaf) this.decaf = firebaseData.decaf;
    if(firebaseData.espresso) this.espresso = firebaseData.espresso;
    if(firebaseData.espressoDecaf) this.espressoDecaf = firebaseData.espressoDecaf;
    if(firebaseData.liquids) this.liquids = firebaseData.liquids;
    if(firebaseData.tea) this.tea = firebaseData.tea;
    if(firebaseData.other) this.other = firebaseData.other;
    if(firebaseData.retail_coffee) this.retail_coffee = firebaseData.retail_coffee;
    if(firebaseData.retail_tea) this.retail_tea = firebaseData.retail_tea;
    if(firebaseData.filters) this.filters = firebaseData.filters;
    if(firebaseData.shippingQB) this.shippingQB = firebaseData.shippingQB;

    if(firebaseData.locationQBdata) this.locationQBdata = firebaseData.locationQBdata;

    if(firebaseData.deliveryComplete) this.deliveryComplete = firebaseData.deliveryComplete;
    if(firebaseData.deliveryDate) this.deliveryDate = firebaseData.deliveryDate;
    if(firebaseData.deliveryMethod) this.deliveryMethod = firebaseData.deliveryMethod;
    if(firebaseData.deliveryCnt) this.deliveryCnt = firebaseData.deliveryCnt;
    if(firebaseData.deliveryNotes) this.deliveryNotes = firebaseData.deliveryNotes;
  }

  toFirebase(){
    return {  orderId: this.orderId,
              appVersion: this.appVersion,
              rawInput: this.rawInput,

              name: this.name,
              phone: this.phone,
              email: this.email,

              organizationName: this.organizationName,
              locationName: this.locationName,
              locationAddress: this.locationAddress,
              locationAddress2: this.locationAddress2,
              locationCity: this.locationCity,
              locationState: this.locationState,
              locationZip: this.locationZip,
              locationPhone: this.locationPhone,
              locationNotes: this.locationNotes,
              locationDeliveryNotes: this.locationDeliveryNotes,

              locationPaymentNotes: this.locationPaymentNotes,
              locationPrintNotes: this.locationPrintNotes,

              shippingStr: this.shippingStr,
              commission: this.commission,

              orderRef: this.orderRef,
              orderDate: this.orderDate,
              orderState: this.orderState,
              archived: this.archived,
              notes: this.notes,

              printStatus: this.printStatus,

              deliveryComplete: this.deliveryComplete,
              deliveryDate: this.deliveryDate,
              deliveryMethod: this.deliveryMethod,
              deliveryCnt: this.deliveryCnt,
              deliveryNotes: this.deliveryNotes,

              coffee: this.coffee,
              coldBrew: this.coldBrew,
              espresso: this.espresso,
              decaf: this.decaf,
              espressoDecaf: this.espressoDecaf,
              liquids: this.liquids,
              tea: this.tea,
              retail_coffee: this.retail_coffee,
              retail_tea: this.retail_tea,
              other: this.other,
              filters: this.filters,
              shippingQB: this.shippingQB,

              locationQBdata: this.locationQBdata
            };

  }
}
